.NSF {
  margin-top: 3rem;
  width: 100%;
  height: 100%;
  padding: 4rem 7rem;

}

.NSFContainer {
  background-color: #fff;
  border-radius: 9px;
  padding: 4rem 10rem 4rem 4rem;
  display: flex;
  gap: 5rem;
  align-items: center;
}

.NSFContainer__left {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.NSFContainer__left > h1 {
  font-weight: 700;
}

.NSFContainer__left > a > button {
  width: fit-content;
}

.NSFContainer > img {
  width: 30%;
}

@media screen and (max-width: 1296px) {
  .NSF {
    padding: 3rem 3rem;
  }
  .NSFContainer {
    padding: 3rem 1rem;
    gap: 5rem;
    flex-direction: column;
  }

  .NSFContainer__left > p {
    /* text-align: justify; */
  }

  .NSFContainer > img {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .NSF {
    padding: 3rem 1rem;
  }
  
  .NSFContainer {
    padding: 3rem 1rem;
    gap: 3rem;
    flex-direction: column;
  }

  .NSFContainer__left > p {
    /* text-align: justify; */
  }

  .NSFContainer > img {
    width: 100%;
  }
}