.deployed_cell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.deployed {
  width: 10px;
  height: 10px;
  border-radius: 99px;
  background-color: #E4E4E4;
}

.deployed.active {
  background-color: #29ABE2;
}


.MuiTablePagination-root p {
  margin: 0px !important
}

.MuiSvgIcon-root {
  color: gray;
  /* font-size: 0.8rem; */
}