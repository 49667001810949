.testimonialWrapper {
    margin-top: 4rem;
    gap: 20px;
    padding: 4rem 2rem 0rem 2rem;
    overflow: hidden;
}

.testimonialContainer .slick-list {
    /* display: flex; */
    /* flex-direction: row; */
    background: #f5f6fa;
    /* justify-content: left; */
    gap: 20px;
    /* padding: 4rem 0rem 0rem 2rem; */
    /* overflow: hidden; */
}

.testimonialContainer .slick-list .slick-slide {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.slick-track {
    display: flex !important;
}

.slick-slide {
    height: inherit !important;
}

.testimonialContainer {
    min-height: 300px;
}

.testimonialBox {
    background: #ffffff;
    /* height: 270px; */
    /* height: inherit; */
    /* height: max-content; */
    min-height: 300px;
    min-width: 200px !important;
    border-radius: 10px;
    border-top: 2px solid #29abe2;
    display: flex !important;
    flex-direction: column;
    padding: 2rem;
    justify-content: space-between;
}

.testimonialImageIcon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.testimonialImage {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.testimonialSignature {
    color: #929292;
    font-style: italic;
}

.slick-arrow {
    display: none !important;
}

.testimonialSliderButtons {
    margin-top: 0.6rem;
}

/* @media (max-width: 450px) {
    .testimonialBox {
        height: 22rem;
    }
} */

.testimonialNavMobBtn{
    display: none !important;
}


@media (max-width: 768px) {
    .testimonialBox {
        /* height: 20.5rem; */
    }
}


@media (max-width: 734px) {


    .testimonialWrapper {
        padding: .5rem;
    }

    .testimonialBox {
        width: 90%;
    }

    .testimonialImageContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .testimonialImageIcon {
        margin-top: 14px;
    }

    .testimonialNavMobBtn {
        margin-top: 6px;
    }


    .testimonialNavMobBtn{
        display: block !important;
    }

    .testimonialSliderButtons {
        display: none;
    }
}


@media (max-width: 413px) {
    .testimonialBox {
        min-height: 340px;
    }
}
