.createVSet {
  width: 100%;
  /* margin-top: 2rem; */
}

.createVSetContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 2rem;
  background-color: #fff;
  padding: 1rem;
}

.createVSetContainer > p {
  margin: 0 !important;
}

.createVSetContainer__selectCat {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.createVSetContainer__selectCat > .ReactSelect {
  width: 100%;
}

.createVSetContainer__insert {
  /* padding: 1rem; */
  border-radius: 99px;
  background-color: #E4E4E4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.createVSetTable {
  margin-top: 2rem;
  background-color: white !important;
  border-radius: 16px;
}

.createVSetTableHeaderFirst {
  padding: 2rem !important;
  background-color: #94A8B0 !important;
  color: white;
}

.createVSetTableHeader {
  padding: 2rem !important;
  background-color: #29ABE2 !important;
  color: white;
}

.createVSetTableTitle {
  text-align: left;
}

table {
  border-collapse: unset !important;
}

.createVSetTableTextArea {
  padding: 1rem;
  border:  1px solid #94A8B0;
  border-radius: 10PX;
}

.createVSetTableCategoryTitle {
  margin-bottom: 5px !important;
  text-align: left !important;
  color: #929292;
}


.createVSetTableCell {
  padding: 0.5rem 1.5rem !important;
}

.createVSetTableIconButton {
  position: absolute;
  
}

.swal2-deny {
  /* margin: 50px 0; */
  background-color: #f84444;
  /* padding: 7px 1rem; */
  /* border: none; */
  border-radius: 10px;
  color: #fff;
}