.card-style {
    /* height: 100%; */
    width: 100%;
    background-color: #009245;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    max-width: 20px;
    min-width: 10px;
    height: 100%;
    float: left;
}

.card-container-header-line {
    color: #29abe2;
    font-weight: bold;
    margin-left: 15px;
}

.card-container-top-line {
    color: #29abe2;
    font-weight: bold;
    margin-left: 15px;
}

.card-top-line {
    color: #29abe2;
}
