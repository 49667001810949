.trust {
    margin-top: 3rem;
    width: 100%;
    height: 100%;
}

.trustContainer {
    /* width: 100%; */
    border-radius: 9px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}

.trustContainer-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    color: #111;
    padding: 4rem 7rem;
}

.trustContainer-top > h1 {
    font-weight: 700;
    text-align: center;
}

.trustContainer-top > p {
    text-align: center;
}

.trustContainer-bottom {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
}

.trustContainer-bottom > img {
}

.trustContainer-bottomBlueContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 3rem;
    background-color: #29abe2;
}

.trustContainer-bottom > img {
    object-fit: contain;
    width: 15%;
}

@media (max-width: 900px) {
    .trustContainer-bottomBlueContainer {
        display: none;
    }

    .trust {
        padding: 3rem 1rem;
    }

    .trustContainer-top > p > br {
        display: none;
    }

    .trustContainer-top > p {
        text-align: center;
    }

    .trustContainer-bottom > img {
        width: 35%;
        object-fit: contain;
    }
}
