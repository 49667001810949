.missionContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 3rem;
    background: #1d9dd3;
    margin: 4rem 0rem 0rem 0rem;
    padding: 5rem 7rem 5rem 7rem;
}

.missionImage {
    width: 15.625rem;
}

.missionContentContainer {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: left;
}

.missionTitle {
    font-weight: 700;
    font-size: 40px;
}

.missionAuthor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
}

.fontItalic {
    font-style: italic;
}

@media (max-width: 767px) {
    .missionContainer {
        flex-wrap: wrap;
    }

    .missionImageContainer {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .missionContainer {
        padding: 5rem 2rem 5rem 2rem;
    }
}
