.collapsiblePanel {
    width: 100%;
    color: black;
    padding: 1rem 2rem;
    border-radius: 6px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
    background-color: #fff;
}

.collapsiblePanel.open {
    background-color: #f8f9fd;
}

.collapsiblePanel__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 0.54px;
    font-size: 0.8rem;
    line-height: 28px;
}

.collapsiblePanel__headerleft {
    display: flex;
    gap: 1rem;
    flex: 1;
    align-items: center;
    color: black;
}

.collapsiblePanel__headerleft.active .MuiSvgIcon-root {
    color: black;
}

.collapsiblePanel:hover .MuiSvgIcon-root {
    color: #29abe2;
}

.collapsiblePanel:hover .collapsiblePanel__headerleft > p {
    color: #29abe2;
}

.collapsiblePanel__headerleft.active > p {
    color: #29abe2;
}

.collapsiblePanel__headerleft.active > .collapsiblePanel__headerleft-Icon {
    border: 1px solid #29abe2;
}

.collapsiblePanel__headerleft > p {
    font-size: 1.1rem;
    margin-bottom: 0 !important;
    font-weight: 700;
}

.collapsiblePanel__headerright {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.collapsiblePanel__headerleft-Icon {
    background-color: #f3f8fb;
    width: 2rem;
    height: 2rem;
    border-radius: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.collapsiblePanel__headerleft .MuiSvgIcon-root {
    transition: all 1s ease-out;
    opacity: 1;
    color: #e4e4e4;
}

.open .collapsiblePanel__header .collapsiblePanel__headerleft .MuiSvgIcon-root {
    /* opacity: 0; */
    transform: rotate(180deg);
}

.collapsiblePanel__headerContent > h3 {
    font-weight: 600;
    color: black;
    margin-bottom: 10px;
}

.collapsiblePanel__header > p {
    color: rgb(32, 32, 32);
    font-weight: 500;
}

.collapsiblePanel .collapsiblePanel__content {
    margin-left: 3rem;
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 1s ease-out;
}

.collapsiblePanel__content > p {
    text-align: justify;
    font-weight: 400;
    color: #111;
    font-weight: 500;
}

.open .collapsiblePanel__content {
    margin-top: 1rem;
    max-height: 1000px;
    opacity: 1;
}

@media screen and (max-width: 768px) {
    .collapsiblePanel {
        width: 100%;
    }
}
