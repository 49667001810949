.v-set-container__options {
    margin: 1rem 0;
    border: 2px solid #bdbdbd;
    border-radius: 4px;
    cursor: pointer;
}

.v-set-container__options__item:not(:last-child) {
    border-bottom: 1px solid #bdbdbd;
}
