.deleteModal {
  padding: 2rem 4rem 35px 4rem !important;
  width: 100%;
  }
  
  .deleteModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .deleteModalContainer__title {
    font-weight: 600;
  }
  
  .deleteModalContainer__desc {
    color: #929292;
    margin-bottom: 0 !important;
    text-align: center;
    font-size: 1.125rem;
  }
  
  .deleteModalContainer > button {
    margin-top: 50px !important;
    background-color: #F84444;
    padding: 7px 1rem;
    border: none;
    border-radius: 5px;
    color: #fff;
  }