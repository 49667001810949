.paymentModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.paymentModal-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.paymentModal-content-header {
  display: flex;
  gap: 3px;
  text-align: center;
}

.paymentModal-content-header > p {

}

.paymentModal-content-description {
  text-align: center;
}

.paymentModal > img {
  width: 50px;
  height: 50px;
}

.paymentModal >  button {
  background-color: #29ABE2;
  color: #fff;
  padding: 5px 2rem;
  border-radius: 10px;
}

.paymentModal >  button:hover {
  background-color: #29ABE2;
}