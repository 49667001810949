.enterprise {
  background-color: #fff;
  margin-top: 5rem;
  width: 100%;
  height: 100%;
  padding: 4rem 7rem;
  display: flex;
  justify-content: center;
  border-radius: 9px;
}

.enterpriseContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.enterpriseContainer__card {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

.enterpriseContainer__card.reverse {
  flex-direction: row-reverse;
}

.enterpriseContainer__cardContent {
  width: 50%;
  color: #111;
}

.enterpriseContainer__cardContent > h1 {
  font-weight: 700;
  margin-bottom: 1rem;
}

.enterpriseContainer__cardContent > p {
  line-height: 28px;
}

.enterpriseContainer__cardImage {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enterpriseContainer__cardImage > img {
  width: 60%;
}

.enterpriseContainer__sector {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.enterpriseContainer__sector > h1 {
  font-weight: 700;
  text-align: center;
}

@media (max-width: 900px) {
  .enterprise {
    padding: 3rem 1rem;
  }
  .enterpriseContainer__card {
    gap: 2rem;
    flex-direction: column-reverse;
  }

  .enterpriseContainer__card.reverse {
    flex-direction: column-reverse;
  }

  .enterpriseContainer__cardContent {
    width: 100%;
  }

  .enterpriseContainer__cardImage > img {
    width: 100%;
  }
}