button.submitButton {
  background-color: #94a8b0;
  padding: 5px 1rem;
  height: 50px;
  color: #fff;
  border-radius: 10px;
  float: right;
  align-items: center;
  /* width: ; */
  /* margin-right: 20px; */
}

button.submitButton:hover {
  background-color: #5F7C88;
}

button.submitButton.submitted {
  background-color: #5F7C88;
  color: white;
}

button.disableSubmitButton {
  background-color: #C0C0C0;
  padding: 5px 1rem;
  height: 50px;
  color: #fff;
  border-radius: 10px;
  float: right;
  align-items: center;
}

button.disableSubmitButton:hover {
  background-color: #C0C0C0;
  cursor: not-allowed;
}