.footerContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem 2rem 4rem;
    background-color: #F5F6FA;
}

.footerBlue {
    color: #29ABE2;
}

.footerLinksContainer {
    display: flex;
    flex-direction: row;
    gap: 5rem;
}

.footerContainer ul {
    list-style: none;
    padding-left: 0px;
}

.footerLinkTitle {
    font-weight: 700 !important;
}

.footerContainer li {
    margin-bottom: 1rem;
    font-weight: 500;
}

.footerContainer li a {
    cursor: pointer;
    text-decoration: none;
    color: #111111;
    transition: all 0.4s ease-in-out;
}

.footerContainer ul a:hover {
    font-weight: 700;
    color: #29ABE2 !important;
    /* transform: scale(1.05); */
}

.footerSocialsContainer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 1rem;
}

.footerCopyrightContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2.5rem;
    color: #929292;
}

@media (max-width: 767px) {

    .footerContainer {
        padding-left: 2rem;
    }

    .footerLinksContainer {
        flex-direction: column;
        gap: 1rem;
    }

    .footerSocialsContainer {
        justify-content: left;
        padding-top: 1rem;
    }

    .footerCopyrightContainer {
        flex-direction: column-reverse;
        gap: 2rem;
    }

}