.deleteVSet {
    padding: 10px 4rem;
    width: 100%;
}

.deleteVSetContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.deleteVSetContainer__title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    color: #111111;
}

.deleteVSetContainer__desc {
    color: #929292;
    font-size: 18px;
    line-height: 28px;
}

.deleteVSetContainer > button {
    margin: 50px 0;
    background-color: #f84444;
    padding: 7px 1rem;
    border: none;
    border-radius: 10px;
    color: #fff;
}
