.compTableContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6rem 7rem 0rem 7rem;
}

.compTable {
    width: 100%;
    border-radius: 10px;
}

.compTable th {
    padding: 1rem 3rem;
    background-color: #e6e8f1;
    vertical-align: middle;
}

.compTable td {
    padding: 1.5rem 3rem;
    background-color: #ffffff;
    vertical-align: middle;
}

.compVsortHeader,
.compVsortData,
.compOtherData {
    text-align: center;
}

.compVsortHeader {
    padding: 1rem 1rem !important;
    border: 2px solid #1d9dd3;
    border-top: none;
}

.compVsortData {
    border-right: 2px solid #1d9dd3;
    border-left: 2px solid #1d9dd3;
}

.compTable tr:last-child td:first-child + td {
    border-bottom: 2px solid #1d9dd3;
}

.compOtherData {
    border-right: 2px solid #f3f8fb;
}

.compTable tr td:last-child {
    border-right: none;
}

.compTable tr:first-child th:first-child {
    border-top-left-radius: 10px;
}

.compTable tr:first-child th:last-child {
    border-top-right-radius: 10px;
}

.compTable tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.compTable tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.compFeatureData {
    width: 30%;
}

.compVsortHeader {
    background-color: #1d9dd3 !important;
}

.compVsortHeader {
    position: relative;
    z-index: 10;
}

.compVsortHeader,
.compVsortData {
    padding: 1rem 0.2rem !important;
    /* transform: scale(1.3); */
    /* scale: 1.3; */
}

@media (max-width: 1024px) {
    .compVsortHeader,
    .compVsortData {
        padding: 1rem 1.3rem !important;
    }
}

@media (max-width: 1023px) {
    .compOtherData3 {
        display: none;
    }

    .compVsortHeader,
    .compVsortData {
        padding: 1rem 2rem !important;
    }
}

@media (max-width: 768px) {
    .compOtherData2 {
        display: none;
    }

    .compTable th {
        padding: 1rem 1rem;
    }

    .compTable td {
        padding: 1rem 1rem;
    }

    .compVsortHeader,
    .compVsortData {
        padding: 1rem 1rem !important;
    }
}

@media (max-width: 495px) {
    .compTableContainer {
        padding: 6rem 1rem 0rem 1rem;
    }

    .compVsortHeader,
    .compVsortData {
        padding: 0.5rem 0.5rem !important;
    }

    .compOtherData,
    .compOtherHeader {
        padding: 0.5rem 0.5rem !important;
    }
}

@media (max-width: 430px) {
    .compFeatureData,
    .compFeatureHeader {
        padding: 0.6rem 0.6rem !important;
    }

    .compFeatureHeader,
    .compOtherHeader {
        font-size: 1rem;
    }

    .compVsortHeader img {
        width: 120px !important;
    }
}

@media (max-width: 374px) {
    .compFeatureData,
    .compFeatureHeader {
        padding: 0.4rem 0.4rem !important;
    }

    .compFeatureHeader,
    .compOtherHeader {
        font-size: 0.8rem;
    }
}
