.sortable-item {
    border: 1px dashed #2fabe2;
    border-radius: 8px;
    min-height: 88px;
    width: 100% !important;
    display: flex;
    align-items: center;
}
.sortable-item:hover {
    background-color: #ddeff8;
    border: 2px dashed #2fabe2 !important;
    /* min-height: 130px; */
    transition: height 0.2s;
}
