.faqs {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 4rem 7rem;
}

.faqsContainer {
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.faqsContainer > h1 {
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
}

@media screen and (max-width: 1296px) {
  .faqs {
    padding: 2rem 3rem;
  }
}

@media (max-width: 768px) {
  .faqs {
    padding: 2rem 0;
  }
}