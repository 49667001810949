.featureSlideWrapper {
    padding: 0rem 2rem 0rem 2rem;
}

.featureSlideContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #F5F6FA;
    padding: 4rem 5rem 0rem 5rem;
}

.featureSlideImageContainer {
    flex: 1;
    background-color: #F8F9FD;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
}

.featureSlideImage {
    max-width: 100%;
}


.featureSlideContentContainer {
    flex: 1;
    background-color: #FFFFFF;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
}

.featureSlideContentTitle {
    font-weight: 700;
    width: 90%;
    margin-bottom: 1.5rem;
}

.featureSlideContainerContent { 
    width: 90%
}

@media (max-width: 930px) {

    .featureSlideContainer {
        flex-direction: column;
        padding: 1rem 0rem;
    }

    .featureSlideWrapper {
        padding: 0rem 1rem 0rem 1rem;
    }

    .featureSlideContentContainer {
        flex-direction: column-reverse;
        border-top-right-radius: 0px;
    }

    .featureSlideContentTitle, .featureSlideContainerContent {
        width: 100%;
    }
}
