@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

html {
    height: 100%;
}

body {
    margin: 0;
    /* font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif !important;
}

#root {
    height: inherit;
}

main {
    margin-top: 50px;
    min-height: 25rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.z-index-super-visible {
    z-index: 9999;
}

.visibility-hidden {
    visibility: hidden;
}

html ::-webkit-scrollbar {
    width: 6px !important;
    scroll-behavior: smooth !important;
    height: 7px;
    margin: -1px;
}
html ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    border-radius: 3px !important;
}
html ::-webkit-scrollbar-thumb {
    background-color: darkgrey !important;
    border-radius: 3px !important;
}
html ::-webkit-scrollbar-corner {
    background-color: transparent;
}

.slick-slider .slick-dots {
    bottom: -50px;
}

.slick-slider .slick-dots li button:before {
    content: '';
    border: 1px solid #000;
    border-radius: 50%;
    opacity: 0.4;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.4);
}

.slick-slider .slick-dots li.slick-active button:before {
    background-color: rgb(37, 162, 213);
    border: none;
}

.faq-summary::before {
    opacity: 0 !important;
}
