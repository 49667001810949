@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.navbarContainer {
    width: 100%;
    /* margin: 0 auto; */
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: url(../../../assets/images/navbarBackground.png) no-repeat; */
    height: 100%;
    /* flex-direction: column; */
    /* height: 100%; */
}

.navbar2 {
    z-index: 1000;
    width: 100vw;
    padding: 0px !important;
    /* padding: 0 3rem; */
    /* height: 80px; */
    position: fixed;
}

.navbar2.show {
    background: hsla(0, 8%, 95%, 1);
}

.navbar2.active {
    background: hsla(0, 8%, 95%, 1);
    /* -webkit-backdrop-filter: blur(15px);
  -moz-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px); */
    box-shadow: 7px 25px 26px 8px rgb(0 0 0 / 10%);
}

.navbar2.active ul a {
    color: #000 !important;
}

.navbar2.active .navbarActionButtons-login {
    color: #000 !important;
}

.nav-elements.active ul a {
    color: #000 !important;
}

.navbarContainertop {
    /* width: 100%; */
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbarContainerLogo {
}

.navbarMenuIcon {
    display: none;
}

.nav-elements {
    display: flex;
    gap: 3rem;
    align-items: center;
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin-bottom: 0 !important;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 3rem;
}

.nav-elements ul a {
    width: 150px !important;
    font-size: 16px;
    font-weight: 400;
    color: #fff !important;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.nav-elements ul a:hover {
    font-weight: 700;
    color: #29abe2 !important;
    transform: scale(1.05);
}

.nav-elements ul a.active {
    font-weight: 500;
    position: relative;
}

.nav-elements ul a.active::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
}

.navbarActionButtons {
    display: flex;
    gap: 3rem;
}

.navbarActionButton {
    outline-width: 0;
    color: #fff;
    border-radius: 11px;
    padding: 10px 2rem;
}

.navbarActionButtons-login {
    background-color: transparent;
    border: 2px solid #29abe2;
    transition: all 0.4s ease-in-out;
}

.navbarActionButtons-getStarted {
    background-color: #29abe2;
    border: none;
}

.navbarActionButtons.active .navbarActionButtons-login {
    color: #000 !important;
}

.heading {
    color: var(--VS-Blue, #29abe2);
    font-family: Montserrat;
    font-size: "204px";
    font-style: "bold";
    font-weight: 1000;
    line-height: 34px; /* 141.667% */
}
@media (max-width: 900px) {
    .navbar2 {
        padding: 1rem 1rem 0 1rem;
    }

    .navbarContainer {
        flex-direction: column;
    }

    .navbarContainertop {
        width: 100%;
    }

    .navbar2.show & .navbar2.active {
        /* box-shadow: 0px 30px 46px 2px rgb(0,0,0,.3); */
        /* box-shadow: 0 2px 4px 0 rgba(0,0,0,.2); */
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    .nav-elements ul li:not(:last-child) {
        margin-right: 0px;
    }

    .navbar2.show .navbarMenuIcon {
        color: #000;
    }

    .navbarMenuIcon {
        color: #fff;
        display: block;
        cursor: pointer;
    }

    .navbar2.active .navbarMenuIcon {
        color: #000;
    }

    .nav-elements {
        display: none;
        width: 0px;
        transition: all 0.3s ease-in;
        gap: 1rem;
        padding-bottom: 1rem;
        /* border-bottom:  1px solid #fff; */
        overflow: hidden;
    }

    .nav-elements.active {
        width: 100vw;
        /* background-color: #7c7c7b; */
        display: flex;
        flex-direction: column;
        background: hsla(0, 8%, 95%, 1);
    }

    .nav-elements ul {
        padding-left: 0 !important;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
    }

    .navbarActionButtons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 1rem;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }
}

@media (max-width: 500px) {
    .navbar2 {
        padding: 10px !important;
    }
}
