.featureContainer {
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap; */
    justify-content: space-between;
    align-items: center;
    background: #F5F6FA;
    padding: 4rem 7rem 4rem 7rem;
}

.featureItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 300px;
    gap: 1rem;
    padding: 1rem;
}

@media (max-width: 900px) {
    .featureContainer {
        flex-wrap: wrap;
        padding: 4rem 2rem 0rem 2rem;
        justify-content: center;
    }
}

@media (max-width: 663px) {
    .featureContainer {
        
        padding: 2rem 1rem 2rem 2rem;
    }
}