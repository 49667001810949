.vSetUnpacking {
  width: 100%;
  height: 100%;
}

.vSetUnpackingContainer__add {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 2rem;
  border-radius: 9px;
}

.vSetUnpackingContainer__add > p {
  width: fit-content !important;
  margin-bottom: 0px !important;
}

.vSetUnpackingContainer__add >  button {
  background-color: #29ABE2;
  height: 3rem;
  width: 3rem;
}

.vSetUnpackingContainer__add > button:hover {
  background-color: #29ABE2;
}

.vSetUnpackingContainer__add > button > .MuiSvgIcon-root {
  color: #fff;
}