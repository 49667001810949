.survey-nestable-list .nestable-item .nestable-list {
    padding: 0;
}

.survey-nestable-list .nestable-item .nestable-item {
    padding: 0;
}

.survey-form-wrapper .sticky-survey-header {
    /* position: -webkit-sticky;  */
    /* position: sticky; */
    /* top: 4rem; */
    background: #fff;
    z-index: 9;
    padding: 1rem 0 1rem;
}
