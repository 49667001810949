.herosection {
  /* width: 100%; */
  height: 100vh;
  background: url(../../../assets/images/HeroSection.png) no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.herosectionContainer {
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.herosectionContainer > p {
  font-size: 4rem;
  font-weight: 700;
  word-wrap: break-word;
  text-align: center;
}

.herosectionContainer > p > span {
  color: #29ABE2;
  display: inline-block;
}

@media (max-width: 900px) {

  .herosection {
  background: url(../../../assets/icons/HerosectionMobile.svg) no-repeat;
  background-size: cover;
  }

  .herosectionContainer > p {
    font-size: 3.2rem;
    text-align: center;
  }
  .herosectionContainer {
    color: #fff;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}