.ctaContainer {
    display: flex;
    flex-direction: row;
    padding: 0rem 0rem 0rem 0rem;
}

.ctaBox {
    flex: 1;
    padding: 2.5rem 4rem;
}

.ctaBox1 {
    background-color: #F5F6FA;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.ctaBox2 {
    background-color: #29ABE2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    gap: 1rem;
}

.ctaBox1Icon {
    width: 16px;
}

.ctaBox1Text {
    font-weight: 700;
}

.textGreen {
    color: #009245;
}

.ctaBox2Text {
    color: #FFFFFF;
    font-weight: 700;
}

@media (max-width: 767px) {
    .ctaContainer {
        flex-direction: column-reverse;
    }

    .ctaBox1 {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
        padding: 2.5rem 2rem;
    }

    .ctaBox2 {
        padding: 2.5rem 2rem;
    }
}
