  .deleteModel {
      padding: 10px 4rem 50px 4rem;
      width: 100%;
  }

  .deleteModelContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.deleteModelContainer__title {
    font-weight: 600;
}

.deleteModelContainer__desc {
    color: #929292;
    margin-bottom: 0 !important;
    text-align: center;
  }
  
  .deleteModelContainer > button {
    margin-top: 50px;
    background-color: #F84444;
    padding: 7px 1rem;
    border: none;
    border-radius: 5px;
    color: #fff;
}
