.saasPlatform {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 7rem 4rem 7rem;
}

.saasPlatformContainer {
  /* width: 80%; */
  background-color: #fff;
  margin-top: -5vh;
  border-radius: 11px;
  padding: 3rem 7rem 3rem  3rem;
  display: flex;
  align-items: center;
  gap: 7rem;
}

.saasPlatformContainer-left {
  color: #111;
}

.saasPlatformContainer-left > h1 {
  font-weight: 700;
  margin-bottom: 2rem;
  line-height: 46px;
}

.saasPlatformContainer-left > p {
  /* text-align: justify; */
  line-height: 28px;
}

.saasPlatformContainer > img {
  object-fit: contain;
  width: 35%;
}

@media screen and (max-width: 2800px) {
  .saasPlatformContainer > img {
    object-fit: contain;
    width: 25%;
  }
}

@media screen and (max-width: 1296px) {
  .saasPlatform {
    padding: 3rem 3rem;
  }
  .saasPlatformContainer {
    width: 95%;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 2rem;
  }

  .saasPlatformContainer > img {
    object-fit: contain;
    width: 85%;
  }
}

@media screen and (max-width: 900px) {
  .saasPlatform {
    padding: 3rem 1rem;
  }
  .saasPlatformContainer {
    width: 95%;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem 1rem;
  }

  .saasPlatformContainer > img {
    object-fit: contain;
    width: 85%;
  }
}
